<style lang="less">
  .resume-router-page {

  }
</style>

<template>
  <router-view class="report-router-page"></router-view>
</template>

<script>
import Vue from 'vue'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI)

export default {}
</script>
